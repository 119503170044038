import React, { useEffect, useState, useRef } from "react";
import Header from "./header";
import Header1 from "./header1";
import Footer from "./footer";
import { FaUser, FaRegEdit } from "react-icons/fa";
import { CiViewList } from "react-icons/ci";
import { MdDelete, MdPayment } from "react-icons/md";
import swal from "sweetalert2";
import Popup from "./Popup"; // Import the popup component
import API from "../api/API"; // Import the new api.js
import { Button, Modal, Form } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function ActiveCust() {
  const [loan, setLoan] = useState([]);
  const [loans, setLoans] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);
  const [showPopup3, setShowPopup3] = useState(false);
  const [showPopup4, setShowPopup4] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [kootuvatti, setKootuvatti] = useState("");
  const [kootuvattiInt, setKootuvattiInt] = useState("");
  const [name, setName] = useState("");
  const [place, setPlace] = useState("");
  const [amount, setAmount] = useState("");
  const [interest, setInterest] = useState("");
  const [periodAgree, setPeriodAgree] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Adjust as needed
  const [searchQuery, setSearchQuery] = useState("");
  const [artt, setArtt] = useState([]);
  const [mobile, setMobile] = useState("");
  const [glno, setGlno] = useState("");
  const [payy, setPayy] = useState([]);
  const [content, setContent] = useState("");
  const [idd, setIdd] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [showModal4, setShowModal4] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState("");
  const [loanId, setLoanId] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleUpdate = () => {
    const isValidAlphabet =
      /^[a-zA-Z ]+$/.test(name) && /^[a-zA-Z ]+$/.test(place);
    const isValidNumeric = /^\d+$/.test(periodAgree) && /^\d+$/.test(amount);
    const isValidDecimal = /^\d+(\.\d+)?$/.test(interest);

    if (!isValidAlphabet) {
      swal.fire({
        title: "Error!",
        text: "Name and Place should have only alphabet characters.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    if (!isValidNumeric) {
      swal.fire({
        title: "Error!",
        text: "Period Agree and Amount should have only Whole Numbers.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    if (!isValidDecimal) {
      swal.fire({
        title: "Error!",
        text: "Interest should have only Numbers.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    fetch(API.updateLoanData, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: loans.id, // Assuming `loans.id` contains the ID of the loan to be updated
        name: name,
        place: place,
        amount: amount,
        interest: interest,
        glno: glno,
        mobile: mobile,
        periodAgree: periodAgree,
      }),
    })
      .then(async (response) => {
        if (response.ok) {
          // Handle success response
          console.log("Loan data updated successfully");
          swal
            .fire({
              title: "Success!",
              text: "Loan data updated successfully", // Assuming the server sends a 'message' field in the response
              icon: "success",
              confirmButtonText: "OK",
            })
            .then(() => {
              // Reload the page
              window.location.reload();
            });
        } else if (response.status === 400) {
          // Deposit detail is already inserted for this month
          const responseData = await response.json(); // await the Promise here
          swal.fire({
            icon: "info",
            title: "Notification",
            text: responseData.error, // Corrected to access the 'error' field
          });
        } else {
          // Handle error response
          console.error("Failed to update loan data");
        }
      })
      .catch((error) => {
        console.error("Error updating loan data:", error);
      });
    setShowPopup2(false);
  };

  const handleUpdate1 = async () => {
    try {
      // Assuming artt contains the updated loan data
      const response = await fetch(API.updateArtData, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(artt),
      });

      if (response.ok) {
        // Handle success
        console.log("Loan data updated successfully");
        swal
          .fire({
            title: "Success!",
            text: "Loan article details updated successfully",
            icon: "success",
            confirmButtonText: "OK",
          })
          .then(() => {
            // Reload the page
            window.location.reload();
          });
      } else if (response.status === 400) {
        // Handle specific error for status 400
        const responseData = await response.json(); // await the Promise here
        swal.fire({
          icon: "info",
          title: "Notification",
          text: responseData.error, // Corrected to access the 'error' field
        });
      } else {
        // Handle other errors
        console.error("Failed to update loan data");
        swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to update loan data. Please try again later.",
        });
      }
    } catch (error) {
      console.error("Error updating loan data:", error);
      swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while updating loan data. Please try again later.",
      });
    }
  };

  useEffect(() => {
    // Check if username and password are present in session storage
    const username = sessionStorage.getItem("username");
    const password = sessionStorage.getItem("password");

    if (!username || !password) {
      // Redirect to login.js if username or password is missing
      window.location.href = "/login";
    }

    const fetchLoan = async () => {
      try {
        const response = await fetch(API.getLoans);
        if (response.ok) {
          const loanData = await response.json();
          setLoan(loanData);
          // console.log(loanData);
        } else {
          console.error("Failed to fetch loan");
        }
      } catch (error) {
        console.error("Error fetching loan:", error);
      }
    };

    fetchLoan();
  }, []);

  const fetchLoans = async (id) => {
    try {
      const response = await fetch(`${API.getLoanById}/${id}`);
      if (response.ok) {
        const loanData = await response.json();
        setIdd(loanData.id);
        setKootuvatti(loanData.kootuvatti_yes_or_no); // Populate select box with fetched data
        setKootuvattiInt(loanData.koottuvatti_intrest); // Populate input field with fetched data
        // console.log(loanData.id);
      } else {
        console.error("Failed to fetch loan by ID");
      }
    } catch (error) {
      console.error("Error fetching loan by ID:", error);
    }
  };

  // const handleEditClick = (id) => {
  //     const storedPassword = sessionStorage.getItem('password');
  //     const enteredPassword = prompt('Enter your password:');
  //     // console.log(enteredPassword);

  //     if (enteredPassword === storedPassword) {
  //         setSelectedId(id); // Set the selected ID for fetching specific loan data
  //         fetchLoans(id); // Pass the selected ID to fetchLoans
  //         setShowPopup(true); // Show the popup
  //     } else if (enteredPassword === null) {

  //     } else {
  //         alert('Incorrect password!');
  //     }
  // };

  const handleEditClick1 = (id) => {
    setSelectedId(id); // Set the selected ID for fetching specific loan data
    fetchLoanBySearch(id); // Pass the selected ID to fetchLoans
    fetchCompanyDetails();
    setShowPopup1(true);
    setShowDetails(true);
  };

  const handleEditClick2 = (id) => {
    setSelectedId(id); // Set the selected ID for fetching specific loan data
    fetchPayBySearch(id); // Pass the selected ID to fetchLoans
    setShowPopup1(true);
  };

  const fetchPayBySearch = async (id) => {
    try {
      const response = await fetch(`${API.getPayBySearch}/${id}`);
      if (response.ok) {
        const PayData = await response.json();
        setShowPopup4(true);
        setShowDetails(true);
        // Ensure PayData is an array
        setPayy(Array.isArray(PayData.data) ? PayData.data : []);
        console.log("14", PayData);
      } else {
        setShowPopup4(false);
        setShowDetails(false);
      }
    } catch (error) {
      console.error("Error fetching payment details:", error);
    }
  };
  

  const handleEditClick4 = (id) => {
    setLoanId(id);
    setShowModal4(true);
  };

  const handlePasswordChange4 = (e) => {
    setEnteredPassword(e.target.value);
  };

  const onSubmit3 = (e) => {
    e.preventDefault();
    handlePasswordSubmit4();
  };

  const handlePasswordSubmit4 = () => {
    setPassword('');
    const storedPassword = sessionStorage.getItem("password");

    if (enteredPassword === storedPassword) {
      setSelectedId(loanId); // Assuming setSelectedId is defined elsewhere
      handleActionOptionClick(loanId); // Assuming handleActionOptionClick is defined elsewhere
      setShowModal4(false);
      setEnteredPassword("");
    } else {
      alert("Incorrect password!");
    }
  };

  const handleModalClose4 = () => {
    setShowModal4(false);
    setEnteredPassword("");
  };

 
  const handleEditClick3 = (id) => {
    setSelectedLoan(id);
    setShowModal3(true);
  };

  const handleModalClose3 = () => {
    setShowModal3(false);
    setPassword("");
    setSelectedLoan(null);
  };

  const onSubmit2 = (e) => {
    e.preventDefault();
    handlePasswordSubmit3();
  };

  const handlePasswordChange3 = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit3 = () => {
    setPassword('');
    const storedPassword = sessionStorage.getItem("password");

    if (password === storedPassword) {
      if (selectedLoan) {
        fetchLoanBySearch(selectedLoan);
        setShowPopup2(true);
        setShowDetails(true);
        setShowModal3(false);
      } else {
        console.error("No loan selected");
      }
    } else {
      alert("Incorrect password!");
    }
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setShowPopup1(false);
    setShowPopup2(false);
    setShowPopup3(false);
    setShowPopup4(false);
  };

  const handleSave = async () => {
    console.log(selectedId);
    if (!Number.isNaN(Number(kootuvattiInt))) {
      try {
        const response = await fetch(API.updateLoan, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ idd, kootuvatti, kootuvattiInt }),
        });
        if (response.ok) {
          console.log("Loan updated successfully");
          swal
            .fire({
              title: "Success!",
              text: "Loan updated successfully",
              icon: "success",
              confirmButtonText: "OK",
            })
            .then(() => {
              // Reload the page
              window.location.reload();
            });
        } else {
          console.error("Failed to update loan");
        }
      } catch (error) {
        console.error("Error updating loan:", error);
      }
    } else {
      // Display a warning swal fire alert if kootuvattiInt is not a number
      swal
        .fire({
          title: "Warning!",
          text: "Please enter a valid number for kootuvatti Interest",
          icon: "warning",
          confirmButtonText: "OK",
        })
        .then(() => {
          // Reload the page
          window.location.reload();
        });
    }
  };

  const fetchLoanBySearch = async (id) => {
    try {
      const response = await fetch(`${API.getLoanBySearches}/${id}`);
      if (response.ok) {
        const loanData = await response.json();
        // Format date before setting the state
        const formattedLoanData = {
          ...loanData,
          dt: new Date(loanData.dt).toLocaleDateString("en-GB"), // Adjust the locale as needed
          third_mnth_start_dt: new Date(
            loanData.third_mnth_start_dt
          ).toLocaleDateString("en-GB"),
        };
        setName(formattedLoanData.nm);
        setPlace(formattedLoanData.place);
        setAmount(formattedLoanData.amt);
        setInterest(formattedLoanData.pawn_intrest);
        setPeriodAgree(formattedLoanData.period_agree);
        setGlno(formattedLoanData.gl_no);
        setMobile(formattedLoanData.cust_mob);
        setLoans(formattedLoanData);
        await fetchArtBySearch1(formattedLoanData.id);
      } else {
        console.error("Failed to fetch loan by search");
      }
    } catch (error) {
      console.error("Error fetching loan by search:", error);
    }
  };

  const fetchArtBySearch1 = async (id) => {
    try {
      const response = await fetch(`${API.getLoanBySearchess}/${id}`);
      if (response.ok) {
        const loanData1 = await response.json();
        let arttContent = "";

        if (Array.isArray(loanData1)) {
          arttContent = loanData1
            .map((item) => `${item.arti}(${item.grm}gm)`)
            .join(", ");
          setContent(arttContent);
        }
      } else {
        console.error("Failed to fetch loan by search");
      }
    } catch (error) {
      console.error("Error fetching loan by search:", error);
    }
  };

  const fetchCompanyDetails = async () => {
    try {
      const response = await fetch(API.getCompanyDetails);
      const result = await response.json();

      if (result.message === "Company details retrieved successfully") {
        const companyDetails = result.data;

        document.getElementById("omobValue").innerText =
          companyDetails.omob || "";
        document.getElementById("cmobValue").innerText =
          companyDetails.cmob || "";
        document.getElementById("lnnoValue").innerText =
          companyDetails.lnno || "";
        document.getElementById("cnameValue").innerText =
          companyDetails.cname || "";
        document.getElementById("caddrValue").innerText =
          companyDetails.caddr || "";
      } else {
        console.log("Error retrieving company details:", result.error);
      }
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
        setShowPopup2(false);
        setShowPopup3(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  // Function to handle loan deletion
  const handleDeleteLoan = async (id) => {
    try {
      const response = await fetch(`${API.deleteLoan}/${id}`, {
        method: "DELETE",
      });

      if (response.ok) {
        swal
          .fire({
            title: "Deleted!",
            text: "Loan Entry has been deleted.",
            icon: "success",
            confirmButtonText: "OK",
          })
          .then(() => {
            // Reload the page
            window.location.reload();
          });
      } else if (response.status === 400) {
        // Deposit detail is already inserted for this month
        const responseData = await response.json(); // await the Promise here
        swal.fire({
          icon: "info",
          title: "Notification",
          text: responseData.error, // Corrected to access the 'error' field
        });
      } else {
        console.error("Failed to delete loan entry");
      }
    } catch (error) {
      console.error("Error deleting loan entry:", error);
    }
  };

  // Function to handle edit/delete option click
  const handleActionOptionClick = (id) => {
    swal
      .fire({
        title: "Delete Confirmation",
        text: "Are you sure you want to delete this Customer Detail?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          // Call the function to handle staff deletion
          handleDeleteLoan(id);
        } else {
          // Reset the selected staff
          setSelectedId(null);
          window.location.reload();
        }
      });
  };

  // Logic to get current items based on currentPage and searchQuery
  const filteredItems = loan.filter((item) => {
    return (
      item.gl_no.includes(searchQuery) ||
      item.nm.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.place.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // Logic to render page numbers
  const visiblePageNumbers = [];
  for (
    let i = Math.max(1, currentPage - 1);
    i <=
    Math.min(currentPage + 1, Math.ceil(filteredItems.length / itemsPerPage));
    i++
  ) {
    visiblePageNumbers.push(i);
  }

  // Function to handle page change
  const handleClick = (event, pageNumber) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // const handleEditClick5 = (id) => {
  //     const storedPassword = sessionStorage.getItem('password');
  //     const enteredPassword = prompt('Enter your password:');

  //     if (enteredPassword === storedPassword) {
  //         setSelectedId(id); // Set the selected ID for fetching specific loan data
  //         fetchArtBySearch(id); // Pass the selected ID to fetchLoans
  //         setShowPopup3(true); // Show the popup
  //     } else if (enteredPassword === null) {

  //     } else {
  //         alert('Incorrect password!');
  //     }
  // };

  const handleEditClick5 = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setPassword("");
    setSelectedId(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleModalSubmit();
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (showPopup3) {
      console.log("show popup" + showPopup3);
    }
  }, [showPopup3]);

  const fetchArtBySearch = async (id) => {
    try {
      const response = await fetch(`${API.getLoanBySearchess}/${id}`);

      if (response.ok) {
        const loanData = await response.json();
        setArtt(loanData);
        setShowPopup3(true);
        console.log("show popup" + showPopup3);
      } else {
        console.error("Failed to fetch loan by search");
        throw new Error("Failed to fetch loan by search");
      }
    } catch (error) {
      console.error("Error fetching loan by search:", error);
      alert("Failed to fetch loan data. .");
    }
  };

  const handleModalSubmit = async () => {
    setPassword('');
    const storedPassword = sessionStorage.getItem("password");
    if (password === storedPassword && selectedId !== null) {
      try {
        await fetchArtBySearch(selectedId);
        setShowModal(false);
        //setShowPopup3(true);
      } catch (error) {
        console.error("Error fetching article:", error);
        alert("Failed to fetch article. Please try again later.");
      }
    } else {
      alert("Incorrect password!");
    }
  };

  const handleArticleChange = (e, index) => {
    const { value } = e.target;
    const updatedArtt = [...artt]; // Create a copy of the current state
    updatedArtt[index] = { ...updatedArtt[index], arti: value }; // Update the article for the specific loan object
    setArtt(updatedArtt); // Update the state with the modified array
    console.log(updatedArtt);
  };

  const handleWeightChange = (e, index) => {
    const { value } = e.target;

    // Validate if the value is a positive number with up to 2 decimal places
    const isValidWeight = /^[+]?\d*\.?\d{0,2}$/.test(value);

    if (!isValidWeight) {
      // Display an error or handle the invalid input appropriately
      console.error("Invalid weight input");
      return;
    }

    const updatedArtt = [...artt];
    updatedArtt[index] = { ...updatedArtt[index], grm: value };
    setArtt(updatedArtt);
    console.log(updatedArtt);
  };

  const handleEditClick = (id) => {
    setSelectedId(id); // Set the selected ID for fetching specific loan data
    setShowModal1(true); // Show the modal for password input
  };

  const handleModalClose1 = () => {
    setShowModal1(false);
    setPassword("");
    setSelectedId(null);
  };

  const onSubmit1 = (e) => {
    e.preventDefault();
    handlePasswordSubmit();
  };

  const handlePasswordChange1 = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    setPassword('');
    const storedPassword = sessionStorage.getItem("password");

    if (password === storedPassword) {
      fetchLoans(selectedId); // Pass the selected ID to fetchLoans
      setShowPopup(true); // Show the popup
      setShowModal1(false); // Hide the modal
    } else {
      alert("Incorrect password!");
    }
  };

  // const handlePopupClose1 = () => {
  //     setShowPopup(false);
  // };

  const convertAmountToWords = (num) => {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const convertLessThanOneThousand = (num) => {
      if (num === 0) return "";
      if (num < 10) return units[num];
      if (num < 20) return teens[num - 10];
      const unitDigit = num % 10;
      const tenDigit = Math.floor(num / 10) % 10;
      const hundredDigit = Math.floor(num / 100);

      let result = "";

      if (hundredDigit > 0) {
        result += `${units[hundredDigit]} Hundred `;
        if (num % 100 !== 0) {
          result += "and ";
        }
      }

      if (tenDigit > 0) {
        result += `${tens[tenDigit]} `;
      }

      if (unitDigit > 0) {
        result += `${units[unitDigit]} `;
      }

      return result.trim();
    };

    const convertLessThanOneCrore = (num) => {
      if (num === 0) return "Zero";
      const crore = Math.floor(num / 10000000);
      const remaining = num % 10000000;
      const lakh = Math.floor(remaining / 100000);
      const thousand = Math.floor((remaining % 100000) / 1000);
      const hundreds = remaining % 1000;

      let result = "";

      if (crore > 0) {
        result += `${convertLessThanOneThousand(crore)} Crore `;
      }

      if (lakh > 0) {
        result += `${convertLessThanOneThousand(lakh)} Lakh `;
      }

      if (thousand > 0) {
        if (thousand === 10) {
          result += "Ten Thousand ";
        } else {
          result += `${convertLessThanOneThousand(thousand)} Thousand `;
        }
      }

      if (hundreds > 0) {
        result += convertLessThanOneThousand(hundreds);
      }

      return result.trim();
    };

    return convertLessThanOneCrore(num);
  };

  return (
    <div className="bghome">
      <Header />
      <Header1 />
      <div style={{ zoom: "0.8" }}>
        <div className="col-md-12 title mt-1">
          <FaUser className="mb-1" /> Members List
        </div>
      </div>
      <div className="vh-100 of" style={{ zoom: 0.8, margin: "0px 10px" }}>
        <div className="text-end me-3">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search..."
            style={{ width: "20%" }}
          />
        </div>
        <table
          className="table table-bordered kj"
          style={{ borderBottom: "transparent" }}
        >
          <thead>
            <tr className="text-center" style={{ verticalAlign: "middle" }}>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Si.No
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Gl.No
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Date
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Name
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Place
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Amount
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Interest
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Articles
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Weight
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Approximate Value
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Period Agree
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                K.V
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                View
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Payment
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Edit
              </th>
              <th
                style={{
                  backgroundColor: "#1C6FB7",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((Loan, index) => {
              const date = new Date(Loan.dt);
              const dateString = date.toLocaleDateString("en-GB"); // Adjust the locale as needed
              return (
                <tr key={index} className="text-center">
                  <td style={{ backgroundColor: "#fff0", fontWeight: "400" }}>
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  <td style={{ backgroundColor: "#fff0", fontWeight: "400" }}>
                    {Loan.gl_no}
                  </td>
                  <td style={{ backgroundColor: "#fff0", fontWeight: "400" }}>
                    {dateString}
                  </td>
                  <td
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {Loan.nm}
                  </td>
                  <td style={{ backgroundColor: "#fff0", fontWeight: "400" }}>
                    {Loan.place}
                  </td>
                  <td style={{ backgroundColor: "#fff0", fontWeight: "400" }}>
                    {Loan.amt}
                  </td>
                  <td style={{ backgroundColor: "#fff0", fontWeight: "400" }}>
                    {Loan.pawn_intrest}
                  </td>
                  <td
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {Loan.article}
                    <button
                      className="art_lst"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleEditClick5(Loan.id)}
                    >
                      Article details
                    </button>
                  </td>
                  <td style={{ backgroundColor: "#fff0", fontWeight: "400" }}>
                    {Loan.weight}
                  </td>
                  <td
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      whiteSpace: "wrap",
                    }}
                  >
                    {Loan.aprox_value}
                  </td>
                  <td style={{ backgroundColor: "#fff0", fontWeight: "400" }}>
                    {Loan.period_agree}
                  </td>
                  <td
                    style={{ backgroundColor: "#fff0", fontWeight: "400" }}
                    className="text-center"
                  >
                    <FaRegEdit
                      size={25}
                      onClick={() => handleEditClick(Loan.id)}
                    />
                  </td>
                  <td
                    style={{ backgroundColor: "#fff0", fontWeight: "400" }}
                    className="text-center"
                  >
                    <CiViewList
                      size={30}
                      onClick={() => handleEditClick1(Loan.id)}
                    />
                  </td>
                  <td
                    style={{ backgroundColor: "#fff0", fontWeight: "400" }}
                    className="text-center"
                  >
                    <MdPayment
                      size={28}
                      onClick={() => handleEditClick2(Loan.id)}
                    />
                  </td>
                  <td
                    style={{ backgroundColor: "#fff0", fontWeight: "400" }}
                    className="text-center"
                  >
                    <FaRegEdit
                      size={25}
                      onClick={() => handleEditClick3(Loan.id)}
                    />
                  </td>
                  <td
                    style={{ backgroundColor: "#fff0", fontWeight: "400" }}
                    className="text-center"
                  >
                    <MdDelete
                      size={25}
                      onClick={() => handleEditClick4(Loan.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="text-center mt-2">
          {currentPage > 1 && (
            <button
              onClick={(e) => handleClick(e, currentPage - 1)}
              className="mx-1 btn"
            >
              Previous
            </button>
          )}
          {visiblePageNumbers.map((number) => (
            <button
              key={number}
              onClick={(e) => handleClick(e, number)}
              className={`mx-1 btn ${number === currentPage ? "active" : ""}`}
            >
              {number}
            </button>
          ))}
          {currentPage < Math.ceil(filteredItems.length / itemsPerPage) && (
            <button
              onClick={(e) => handleClick(e, currentPage + 1)}
              className="mx-1 btn"
            >
              Next
            </button>
          )}
        </div>
      </div>
      <div className="fixed-bottom">
        <Footer />
      </div>
      {/* articles */}
      <Modal show={showModal}>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group
              controlId="formBasicPassword"
              className="position-relative"
            >
              <Form.Label>Enter your Password</Form.Label>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={handlePasswordChange}
              />
              <div
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "14px",
                  top: "70%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalSubmit}>
            Submit
          </Button>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* k.v */}
      <Modal show={showModal1}>
        <Modal.Body>
          <Form onSubmit={onSubmit1}>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Enter your Password</Form.Label>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={handlePasswordChange1}
              />
              <div
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "30px",
                  top: "65%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handlePasswordSubmit}>
            Submit
          </Button>
          <Button variant="secondary" onClick={handleModalClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit */}
      <Modal show={showModal3}>
        <Modal.Body>
          <Form onSubmit={onSubmit2}>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Enter your Password</Form.Label>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={handlePasswordChange3}
              />
              <div
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "30px",
                  top: "65%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handlePasswordSubmit3}>
            Submit
          </Button>
          <Button variant="secondary" onClick={handleModalClose3}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete */}
      <Modal show={showModal4}>
        <Modal.Body>
          <Form onSubmit={onSubmit3}>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Enter your Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                value={enteredPassword}
                onChange={handlePasswordChange4}
              />
              <div
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "30px",
                  top: "63%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handlePasswordSubmit4}>
            Submit
          </Button>
          <Button variant="secondary" onClick={handleModalClose4}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {showPopup && (
        <div>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex:'1000'
            }}
          >
            <div
              style={{
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                width: "400px",
              }}
              ref={popupRef}
            >
              <div className="text-center">
                <label>
                  <b>Edit Koottuvatti Details</b>
                </label>
                <button
                  onClick={handlePopupClose}
                  className="btn"
                  style={{ position: "relative", left: "25%" }}
                >
                  {" "}
                  X{" "}
                </button>
              </div>
              <div className="mt-3">
                Koottuvatti Status : {kootuvatti || ""}
              </div>
              <select
                value={kootuvatti || ""}
                onChange={(e) => setKootuvatti(e.target.value)}
                className="mt-1"
              >
                <option disabled>select the option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
              <div className="mt-3">
                Koottuvatti Interest : {kootuvattiInt || ""}
              </div>
              <input
                type="text"
                value={kootuvattiInt || ""}
                placeholder="0"
                onChange={(e) => setKootuvattiInt(e.target.value)}
                className="mt-1"
              />
              <input
                type="hidden"
                value={idd || "0"}
                onChange={(e) => setIdd(e.target.value)}
                className="mt-1"
              />
              <div className="mt-3 text-end">
                <button className="btn btn-primary" onClick={handleSave}>
                  Save Changes
                </button>{" "}
                <button className="btn btn-primary" onClick={handlePopupClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      
      {showPopup1 && (
        <Popup onClose={handlePopupClose}>
          <div
            className="mb-2 modal-header1"
            style={{ padding: "5px", textAlign: "right" }}
          >
            <button onClick={handlePopupClose} className="btn close-btn">
              X
            </button>
          </div>
          <div
            className={showDetails ? "col-md-11" : "dnone"}
            style={{ fontSize: "14px", whiteSpace: "nowrap", padding: "5px" }}
          >
            <table className="table table-bordered border-dark text-center m-1">
              <tbody>
                <tr>
                  <td
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>L.N.TN-</b>
                    <span id="lnnoValue"></span>
                  </td>
                  <td
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>Off : </b>
                    <span id="omobValue"></span>
                    <br />
                    <b>Mob : </b>
                    <span id="cmobValue"></span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                    colSpan="2"
                  >
                    <b>
                      <span id="cnameValue"></span>
                    </b>
                    <br />
                    <span id="caddrValue"></span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>Name : </b>
                    {loans && loans.nm}
                  </td>
                  <td
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>Date : </b>
                    {loans && loans.dt}
                    <br />
                    <b>Gl.No : </b>
                    {loans && loans.gl_no}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>Mobile : </b>
                    {loans && loans.cust_mob}
                  </td>
                  <td
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>Place : </b>
                    {loans && loans.place}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>Address of Pawner : </b>
                    {loans && loans.addr}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>Amount : </b>
                    {loans && loans.amt} (
                    {convertAmountToWords(loans && loans.amt)} Only /-)
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>Details of Articles : </b>
                    {content}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>Weight : </b>
                    {loans && loans.weight}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>Approximate Value : </b>
                    {loans && loans.aprox_value}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>Period agree : </b>
                    {loans && loans.period_agree}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <span
            className={showDetails ? "col-md-11" : "dnone"}
            style={{ fontSize: "14px", padding: "5px" }}
          >
            Calculation
          </span>
          <div
            className={showDetails ? "col-md-11 mb-2" : "dnone"}
            style={{ fontSize: "14px", padding: "5px" }}
          >
            <table className="table table-bordered border-dark text-center m-1">
              <tbody>
                <tr>
                  <td
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>Time Period</b>
                  </td>
                  <td
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>Time Amount</b>
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-start"
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>1 Year</b>
                  </td>
                  <td
                    className="text-start"
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    {loans && loans.one_yr_amt}
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-start"
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>1 Month</b>
                  </td>
                  <td
                    className="text-start"
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    {loans && loans.one_mnth_amt}
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-start"
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>1 Day</b>
                  </td>
                  <td
                    className="text-start"
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    {loans && loans.one_day_amt}
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-start"
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    <b>Min - 15 Days</b>
                  </td>
                  <td
                    className="text-start"
                    style={{
                      backgroundColor: "#fff0",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                  >
                    {loans && loans.seven_day_amt}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Popup>
      )}
       {showPopup2 && (
  <div>
      <div style={{ zIndex:'1000', position: 'fixed', top: 0, right: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <div style={{ background: '#fff', padding: '20px', borderRadius: '8px', width: '400px', position: 'relative' }} ref={popupRef}>
              <button onClick={handlePopupClose} className='btn' style={{ position: 'absolute', top: '10px', right: '5px' }} > X </button>
              <div>
                  <label className='ms-1'><b style={{fontWeight: '600'}}>Name</b></label><br />
                  <input type='text' className='ms-2' style={{margin: '5px 5px', width: '90%', padding: '6px'}} value={name} placeholder={loans.nm} onChange={(e) => setName(e.target.value)} />
              </div>
              <div>
                  <label className='ms-1'><b style={{fontWeight: '600'}}>Place</b></label><br />
                  <input type='text' className='ms-2' style={{margin: '5px 5px', width: '90%', padding: '6px'}} value={place} placeholder={loans.place} onChange={(e) => setPlace(e.target.value)} />
              </div>
              <div>
                  <label className='ms-1'><b style={{fontWeight: '600'}}>Amount</b></label><br />
                  <input type='text' className='ms-2' style={{margin: '5px 5px', width: '90%', padding: '6px'}} value={amount} placeholder={loans.amt} onChange={(e) => setAmount(e.target.value)} />
              </div>
              <div>
                  <label className='ms-1'><b style={{fontWeight: '600'}}>Interest</b></label><br />
                  <input type='text' className='ms-2' style={{margin: '5px 5px', width: '90%', padding: '6px'}} value={interest} placeholder={loans.pawn_intrest} onChange={(e) => setInterest(e.target.value)} />
              </div>
              <div>
                  <label className='ms-1'><b style={{fontWeight: '600'}}>Period Agree</b></label><br />
                  <input type='text' className='ms-2' style={{margin: '5px 5px', width: '90%', padding: '6px'}} value={periodAgree} placeholder={loans.period_agree} onChange={(e) => setPeriodAgree(e.target.value)} />
              </div>
              <div className='col-md-12 text-center mt-3'>
                  <button className='btn btn-success' onClick={handleUpdate}>Set Now</button>
              </div>
          </div>
      </div>
  </div>
)}  
      {showPopup3 && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex:'1000'
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "8px",
              width: "400px",
              position: "relative",
            }}
            ref={popupRef}
          >
            <button
              onClick={handlePopupClose}
              className="btn"
              style={{ position: "absolute", top: "10px", right: "10px" }}
            >
              X
            </button>
            {artt.map((loan, index) => (
              <div key={index}>
                <div>
                  <label className="ms-1">
                    <b style={{ fontWeight: "600" }}>Name</b>
                  </label>
                  <br />
                  <input
                    type="text"
                    className="ms-2"
                    style={{ margin: "5px 5px", width: "90%", padding: "6px" }}
                    value={loan.arti}
                    placeholder="Article"
                    onChange={(e) => handleArticleChange(e, index)}
                  />
                </div>
                <div>
                  <label className="ms-1">
                    <b style={{ fontWeight: "600" }}>Weight</b>
                  </label>
                  <br />
                  <input
                    type="text"
                    className="ms-2"
                    style={{ margin: "5px 5px", width: "90%", padding: "6px" }}
                    value={loan.grm}
                    placeholder="Weight"
                    onChange={(e) => handleWeightChange(e, index)}
                  />
                </div>
              </div>
            ))}
            <div className="col-md-12 text-center mt-3">
              <button className="btn btn-success" onClick={handleUpdate1}>
                Set Now
              </button>
            </div>
          </div>
        </div>
      )}
      {showPopup4 && (
        <>
          <Popup>
            <div className="mb-2 modal-header1">
              <button
                onClick={handlePopupClose}
                className="btn"
                style={{ position: "relative", left: "45%" }}
              >
                {" "}
                X{" "}
              </button>
            </div>
            <div
              className={showDetails ? "col-md-11" : "dnone"}
              style={{ fontSize: "14px", whiteSpace: "nowrap" }}
              onMouseDown={(e) => e.stopPropagation()}
            >
              
              {Array.isArray(payy) && payy.length > 0 ? (
  payy.map((Pay, index) => {
    const date = new Date(Pay.paid_date);
    const dateString = date.toLocaleDateString("en-GB"); // Adjust the locale as needed
    return (
      <table
        key={index}
        className="table table-bordered border-dark text-center m-3"
      >
        <thead>
          <tr>
            <th
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
              colSpan="8"
            >
              Payment Detail {index + 1}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              <b>Si.no</b>
            </td>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              <b>Date</b>
            </td>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              <b>Withdraw</b>
            </td>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              <b>Article</b>
            </td>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              <b>Weight</b>
            </td>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              <b>Amount</b>
            </td>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              <b>Interest</b>
            </td>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              <b>Total</b>
            </td>
          </tr>
          <tr>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              {index + 1}
            </td>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              {dateString}
            </td>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              {Pay.article}
            </td>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              {Pay.article}
            </td>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              {Pay.weight}
            </td>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              {Pay.payable_amt}
            </td>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              {Pay.interest}
            </td>
            <td
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              {Pay.payable_amt}
            </td>
          </tr>
          <tr>
            <td
              colSpan="8"
              className="text-end"
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              <b>Paid Amount : </b>
              {Pay.paid_amt}
            </td>
          </tr>
          <tr>
            <td
              colSpan="8"
              className="text-end"
              style={{
                backgroundColor: "#fff0",
                fontWeight: "400",
              }}
            >
              <b>Balance Amount : </b>
              {Pay.bal_amt}
            </td>
          </tr>
        </tbody>
      </table>
    );
  })
) : (
  <p>No payment details available.</p>
)}
            </div>
          </Popup>
        </>
      )}
    </div>
  );
}

export default ActiveCust;

import React, { useRef, useEffect } from 'react';

const Popup = ({ children, onClose }) => {
    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose(); // Call the onClose function passed from the parent component
            }
        };

        // Add event listener to listen for clicks outside the popup
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    return (
        <div 
            style={{ 
                position: 'fixed', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%', 
                background: 'rgba(0, 0, 0, 0.5)', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                zoom: '0.9',
                zIndex:'1000'
            }}
        >
            <div 
                ref={popupRef} 
                style={{ 
                    background: '#fff', 
                    padding: '20px', 
                    borderRadius: '8px', 
                    width: '90%', 
                    maxWidth: '1000px', 
                    height: '90%', 
                    maxHeight: '90%', 
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', 
                    display: 'flex', 
                    flexDirection: 'column',
                    overflow: 'hidden', // Ensure no scrollbar appears
                    marginBottom:'30px',
                zIndex:'1000'

                }}
            >
                {children}
            </div>
            <style jsx>{`
                @media (max-width: 768px) {
                    div {
                        width: 95%;
                        height: 95%;
                        max-width: 90vw;
                        max-height: 90vh;
                        padding: 10px;
                        
                    }
                }
                @media (max-width: 480px) {
                    div {
                        width: 98%;
                        height: 98%;
                        max-width: 100vw;
                        max-height: 100vh;
                        padding: 5px;
                    }
                }
            `}</style>
        </div>
    );
};

export default Popup;
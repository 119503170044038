import React, { useEffect, useState } from "react";
import Header from "./header";
import Header1 from "./header1";
import Footer from "./footer";
import { IoIosPeople } from "react-icons/io";
import { FaEye, FaEyeSlash  } from "react-icons/fa";
import swal from "sweetalert2";
import API from "../api/API"; // Import the new api.js
import axios from "axios";

function Staff() {
  const [nextStaffId, setNextStaffId] = useState("");
  const [staffs, setStaffs] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [editedStaff, setEditedStaff] = useState({
    paswd: "",
    plce: "",
    addr: "",
    contno: "",
    name: "",
    doj: "",
    salry: "",
    brch_id: "",
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Adjust as needed
  const [showPassword, setShowPassword] = useState(false);
  const [doj, setDoj] = useState("");

  useEffect(() => {
    // Fetch the date of joining from the backend
    const fetchDoj = async () => {
      try {
        const response = await axios.get(API.dateOfJoining);
        const dateOfJoining = response.data[0]?.doj;
        console.log("dateOfJoining",dateOfJoining);

        if (dateOfJoining) {
          const localDate = new Date(dateOfJoining).toISOString().split("T")[0];
          setDoj(localDate);
        }
      } catch (error) {
        console.error("Error fetching date of joining", error);
      }
    };

    fetchDoj();
  }, []);



  const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

  useEffect(() => {
    const username = sessionStorage.getItem("username");
    const password = sessionStorage.getItem("password");
    const userType = sessionStorage.getItem("userType");

    if (!username || !password) {
      // Redirect to login.js if username or password is missing
      window.location.href = "/";
    }

    if (userType === "staff") {
      window.history.back();
    }

    fetchBranches();
    fetchStaffs();
  });

  const fetchBranches = async () => {
    try {
      const response = await fetch(API.getBranches);
      if (response.ok) {
        const branchesData = await response.json();
        setBranches(branchesData);
      } else {
        console.error("Failed to fetch branches");
      }
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const fetchStaffs = async () => {
    try {
      const response = await fetch(API.getStaffs);
      if (response.ok) {
        const staffsData = await response.json();
        // Exclude the first entry (admin) when finding the last staff
        const nonAdminStaffs = staffsData.filter(
          (staff) => staff.us_nm !== "admin"
        );
        const lastStaff = nonAdminStaffs[nonAdminStaffs.length - 1];
        const lastStaffId = lastStaff ? lastStaff.us_nm : 0;
        setNextStaffId(generateNextStaffId(lastStaffId));
        setStaffs(nonAdminStaffs);
      } else {
        console.error("Failed to fetch staff");
      }
    } catch (error) {
      console.error("Error fetching staff:", error);
    }
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = staffs.slice(indexOfFirstItem, indexOfLastItem);

  // Logic to render page numbers
  const visiblePageNumbers = [];
  for (
    let i = Math.max(1, currentPage - 1);
    i <= Math.min(currentPage + 1, Math.ceil(staffs.length / itemsPerPage));
    i++
  ) {
    visiblePageNumbers.push(i);
  }

  // Function to handle page change
  const handleClick = (event, pageNumber) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const generateNextStaffId = (lastStaffId) => {
    const numericPart = lastStaffId.replace(/[^\d]/g, "");
    const nextNumericPart = String(Number(numericPart) + 1).padStart(
      numericPart.length,
      "0"
    );
    const prefix = lastStaffId.replace(/\d/g, "");
    return `${prefix}${nextNumericPart}`;
  };

  const handleSave = async () => {
    const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format
    const staffData = {
      us_nm: document.getElementsByName("sid")[0].value,
      paswd: document.getElementsByName("lp")[0].value,
      plce: document.getElementsByName("plc")[0].value,
      addr: document.getElementsByName("addr")[0].value,
      contno: document.getElementsByName("cno")[0].value,
      name: document.getElementsByName("snm")[0].value,
      doj: document.getElementsByName("doj")[0].value,
      salry: document.getElementsByName("slry")[0].value,
      brch_id: document.getElementsByName("brh")[0].value,
      active_dactive: "active",
      dept: "staff",
      dte: currentDate,
      ln_no: "",
      off_no: "",
      licence: "",
    };

    const fieldNamesMap = {
      name: "Staff Name",
      plce: "Place",
      addr: "Address",
      contno: "Contact",
      salry: "Salary",
    };

    const requiredFields = [
      "paswd",
      "plce",
      "addr",
      "contno",
      "name",
      "doj",
      "salry",
    ];
    const validationErrors = [];

    const isAnyFieldEmpty = requiredFields.some((field) => {
      const fieldValue = staffData[field];

      if (["name"].includes(field)) {
        if (/[^a-zA-Z ]/.test(fieldValue)) {
          validationErrors.push(`${fieldNamesMap[field]} cannot have numbers.`);
          return true;
        }
      }

      if (["plce"].includes(field)) {
        if (/[^a-zA-Z ]/.test(fieldValue)) {
          validationErrors.push(
            `${fieldNamesMap[field]} should have only alphabetic characters.`
          );
          return true;
        }
      }

      if (field === "contno") {
        if (!/^[6-9]\d{9}$/.test(fieldValue)) {
          validationErrors.push(
            `${fieldNamesMap[field]} should be a 10-digit number starting from 6-9.`
          );
          return true;
        }
      }

      if (!fieldValue) {
        validationErrors.push(`All values needed to store the Staff entry.`);
        return true;
      }

      return false;
    });

    if (isAnyFieldEmpty) {
      // Show error notifications for each validation error
      validationErrors.forEach((error) => {
        swal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
      return; // Stop further execution if any field is empty or invalid
    }

    try {
      const response = await fetch(API.addStaff, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(staffData),
      });

      if (response.ok) {
        swal
          .fire({
            title: "Success!",
            text: "Staff added successfully",
            icon: "success",
            confirmButtonText: "OK",
          })
          .then(() => {
            // Reload the page
            window.location.reload();
          });
        console.log("Staff added successfully");
      } else {
        const responseData = await response.text(); // Read response as text
        if (response.status === 408) {
          swal.fire({
            icon: "info",
            title: "Notification!",
            text: responseData, // Display the error message from the server
          });
        } else {
          console.error("Failed to add branch:", responseData);
        }
      }
    } catch (error) {
      console.error("Error adding Staff:", error);
    }
  };

  const handleViewDetails = async (staffId) => {
    try {
      // Find the staff with the clicked ID
      const clickedStaff = staffs.find((staff) => staff.us_nm === staffId);

      // Fetch branch details for the selected staff's branch ID
      const branchResponse = await fetch(
        `${API.getBranch}/${clickedStaff.brch_id}`
      );
      if (branchResponse.ok) {
        const branchData = await branchResponse.json();
        const formattedDOJ = new Date(clickedStaff.doj).toLocaleDateString();
        console.log(formattedDOJ);

        // Update the selectedStaff with branch details and formatted date
        setSelectedStaff({
          ...clickedStaff,
          id: clickedStaff.us_nm, // Add this line to set the 'id' property
          branchDetails: `${branchData.brch_code} - ${branchData.brch_nm}`,
          doj: formattedDOJ,
        });
      } else {
        console.error("Failed to fetch branch details");
      }
    } catch (error) {
      console.error("Error fetching staff or branch details:", error);
    }
  };

  const handleNumberInput = (e) => {
    const value = e.target.value;
    const isValidInput = /^[0-9]*\.?[0-9]*$/.test(value);

    if (!isValidInput) {
      e.target.value = value.replace(/[^0-9.]/g, "");
    }
  };

  const handleStatusChange = async (staffId, newStatus) => {
    try {
      const response = await fetch(`${API.updateStaffStatus}/${staffId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ active_dactive: newStatus }),
      });

      if (response.ok) {
        swal
          .fire({
            title: "Success!",
            text: "Status updated successfully", // Assuming the server sends a 'message' field in the response
            icon: "success",
            confirmButtonText: "OK",
          })
          .then(() => {
            // Reload the page
            window.location.reload();
          });
        console.log("Status updated successfully");
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating/deleting branch:", error);
    }
  };

  // Function to handle edit/delete option click
  const handleActionOptionClick = (staffId, mode) => {
    // setSelectedStaffIdForAction(staffId);
    // setActionMode(mode);

    // Add logic for showing a confirmation dialog for delete
    if (mode === "delete") {
      swal
        .fire({
          title: "Delete Confirmation",
          text: "Are you sure you want to delete this staff?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            // Call the function to handle staff deletion
            handleDeleteStaff(staffId);
          } else {
            // Reset the selected staff
            setSelectedStaff(null);
            window.location.reload();
          }
        });
    } else {
      handleEditClick(staffId);
    }
  };

  // Function to handle staff deletion
  const handleDeleteStaff = async (staffId) => {
    try {
      const response = await fetch(`${API.deleteStaff}/${staffId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        swal
          .fire({
            title: "Deleted!",
            text: "Staff has been deleted.",
            icon: "success",
            confirmButtonText: "OK",
          })
          .then(() => {
            // Reload the page
            window.location.reload();
          });
      } else {
        console.error("Failed to delete staff");
      }
    } catch (error) {
      console.error("Error deleting staff:", error);
    }
  };

  // Function to handle edit option click
  const handleEditClick = (staffId) => {
    // Find the staff with the clicked ID
    const clickedStaff = staffs.find((staff) => staff.us_nm === staffId);
    const NewformattedDOJ = new Date(clickedStaff.doj).toLocaleDateString(
      "en-GB"
    );
    console.log(NewformattedDOJ);
    const parts = NewformattedDOJ.split("/");
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    const formattedDOJ = `${year}-${month}-${day}`;
    console.log("formattedDOJ", formattedDOJ);

    // Populate the editedStaff state with the details of the selected staff
    setEditedStaff({
      paswd: clickedStaff.paswd,
      plce: clickedStaff.plce,
      addr: clickedStaff.addr,
      contno: clickedStaff.contno,
      name: clickedStaff.name,
      doj: formattedDOJ,
      salry: clickedStaff.salry,
      brch_id: clickedStaff.brch_id,
    });

    // Open the edit modal
    setIsEditModalOpen(true);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;

   // Function to enforce contact number constraints
   const enforceNumberConstraints = (value) => {
    // Remove non-digit characters
    const sanitizedValue = value.replace(/\D/g, '');

    // Ensure the value starts with 6-9 and limit to 10 digits
    if (sanitizedValue.length > 0 && !/^[6-9]/.test(sanitizedValue)) {
        return ''; // Clear the input if it does not start with 6-9
    }

    return sanitizedValue.slice(0, 10); // Limit to 10 digits
};

// Apply input constraints for the contact number field
if (name === 'contno') {
    const constrainedValue = enforceNumberConstraints(value);
    setEditedStaff((prevEditedStaff) => ({
        ...prevEditedStaff,
        [name]: constrainedValue,
    }));
    return;
}

    // Validation logic
    const validationErrors = [];

    if (!value.trim()) {
      validationErrors.push("All values must be filled.");
    }

    if (["name"].includes(name)) {
      if (/[^a-zA-Z]/.test(value)) {
        validationErrors.push("Staff Name cannot have numbers.");
      }
    }

    if (["plce"].includes(name)) {
      if (/[^a-zA-Z]/.test(value)) {
        validationErrors.push("Place should have only alphabetic characters.");
      }
    }

 
    // Add more validation rules as needed
    // Show error notifications for each validation error
    if (validationErrors.length > 0) {
      validationErrors.forEach((error) => {
        swal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
      return; // Stop further execution if any validation error
    }

    // If validation passes, update the state
    setEditedStaff((prevEditedStaff) => ({
      ...prevEditedStaff,
      [name]: value,
    }));
  };

  const handleEditSubmit = async () => {
    // Validate the editedStaff data before sending it to the server
    const validationErrors = [];
    let existingNumbers = [];

    // Fetch existing staff numbers
    try {
      const response = await fetch(API.StaffNumber);
      if (response.ok) {
          existingNumbers = await response.json();
          // Filter out any empty strings and ensure all numbers are strings
          existingNumbers = existingNumbers.map(row => row.contno).filter(number => number.trim() !== '');
      } else {
          console.error("Failed to fetch existing staff numbers");
          swal.fire({
              title: "Error!",
              text: "Failed to fetch existing staff numbers",
              icon: "error",
              confirmButtonText: "OK",
          });
          return;
      }
  } catch (error) {
      console.error("Error fetching existing staff numbers:", error);
      swal.fire({
          title: "Error!",
          text: "An unexpected error occurred while fetching existing staff numbers",
          icon: "error",
          confirmButtonText: "OK",
      });
      return;
  }



   // Example validation (adjust according to your actual requirements)
const mobileRegex = /^[6-9]\d{9}$/;

// Check if the contact number is valid
if (!mobileRegex.test(editedStaff.contno)) {
    validationErrors.push("Contact Number should be a 10-digit number starting from 6-9.");
} else if (existingNumbers.includes(editedStaff.contno) && editedStaff.contno !== selectedStaff.contno) {
    validationErrors.push("Contact Number already exists.");
}

    if (!editedStaff.name.trim()) {
        validationErrors.push("Staff Name is required.");
    } else if (/[^a-zA-Z]/.test(editedStaff.name)) {
        validationErrors.push("Staff Name cannot have numbers.");
    }

    if (!editedStaff.plce.trim()) {
        validationErrors.push("Place is required.");
    } else if (/[^a-zA-Z]/.test(editedStaff.plce)) {
        validationErrors.push("Place should have only alphabetic characters.");
    }

    // Show error notifications if validation fails
    if (validationErrors.length > 0) {
        validationErrors.forEach((error) => {
            swal.fire({
                title: "Error!",
                text: error,
                icon: "error",
                confirmButtonText: "OK",
            });
        });
        return; // Stop further execution if validation errors exist
    }

    // If validation passes, proceed with the update
    try {
        const response = await fetch(`${API.updateStaff}/${selectedStaff.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(editedStaff),
        });

        if (response.ok) {
            swal
                .fire({
                    title: "Success!",
                    text: "Staff details updated successfully",
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then(() => {
                    // Reload the page or update the staff list
                    window.location.reload();
                });
        } else {
            // Handle errors based on server response
            const errorResult = await response.json();
            swal.fire({
                title: "Error!",
                text: errorResult.error || "Failed to update staff details",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    } catch (error) {
        console.error("Error updating staff details:", error);
        swal.fire({
            title: "Error!",
            text: "An unexpected error occurred",
            icon: "error",
            confirmButtonText: "OK",
        });
    } finally {
        // Close the edit modal regardless of success or failure
        setIsEditModalOpen(false);
    }
};



  const handlePopupClose = () => {
    setIsEditModalOpen(false);
    refreshPage();
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="bghome">
      <Header />
      <Header1 />
      <div style={{ zoom: 0.8 }} className="col-md-12 title">
        <IoIosPeople className="mb-1" size={25} /> Add Staff
      </div>
      <div className="col-md-11 le" style={{ zoom: 0.8 }}>
        <div className="col-md-5 mx-4 my-4 lfb">
          <div className="col-md-12 d-flex mb-3">
            <div className="col-md-6 ms-4">
              <label>
                <b style={{ fontWeight: "600" }}>Staff User ID</b>
              </label>
              <br />
              <input
                type="text"
                style={{ margin: "5px 5px", width: "90%", padding: "6px" }}
                name="sid"
                value={nextStaffId ? nextStaffId : "STF0001"}
                readOnly
              />
            </div>
            <div className="col-md-6">
              <label>
                <b style={{ fontWeight: "600" }}>Login Password</b>
              </label>
              <br />
              
              <input
                  type={showPassword? 'text' : "password" }
                  style={{ margin: "5px 5px", width: "90%", padding: "6px" }}
                name="lp"
              />
              <div
            onClick={togglePasswordVisibility}
            style={{
              position: 'relative',
              left: '245px',
              top: '-27%',
              transform: 'translateY(-50%)',
              cursor: 'pointer'
            }}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </div>
             
            </div>
          </div>
          <div className="col-md-12 d-flex mb-3">
            <div className="col-md-6 ms-4">
              <label>
                <b style={{ fontWeight: "600" }}>Staff Name</b>
              </label>
              <br />
              <input
                type="text"
                style={{ margin: "5px 5px", width: "90%", padding: "6px" }}
                name="snm"
              />
            </div>
            <div className="col-md-6">
              <label>
                <b style={{ fontWeight: "600" }}>Staff's Contact Number</b>
              </label>
              <br />
              <input
                type="text"
                style={{ margin: "5px 5px", width: "90%", padding: "6px" }}
                name="cno"
                onChange={handleNumberInput}
              />
            </div>
          </div>
          <div className="col-md-12 d-flex mb-3">
            <div className="col-md-6 ms-4">
              <label>
                <b style={{ fontWeight: "600" }}>Place</b>
              </label>
              <br />
              <input
                type="text"
                style={{ margin: "5px 5px", width: "90%", padding: "6px" }}
                name="plc"
              />
            </div>
            <div className="col-md-6">
              <label>
                <b style={{ fontWeight: "600" }}>Address</b>
              </label>
              <br />
              <textarea
                style={{ margin: "5px 5px", width: "90%", padding: "6px" }}
                rows="3"
                name="addr"
              ></textarea>
            </div>
          </div>
          <div className="col-md-12 d-flex mb-3">
            <div className="col-md-6 ms-4">
              <label>
                <b style={{ fontWeight: "600" }}>Date of Joining</b>
              </label>
              <br />
              <input
                type="date"
                min={doj}
                style={{ margin: "5px 5px", width: "90%", padding: "6px" }}
                name="doj"
              />
            </div>
            <div className="col-md-6">
              <label>
                <b style={{ fontWeight: "600" }}>Salary</b>
              </label>
              <br />
              <input
                type="text"
                style={{ margin: "5px 5px", width: "90%", padding: "6px" }}
                name="slry"
                onChange={handleNumberInput}
              />
            </div>
          </div>
          <div className="col-md-12 d-flex mb-3">
            <div className="col-md-6 ms-4">
              <label>
                <b style={{ fontWeight: "600" }}>Branch</b>
              </label>
              <br />
              <select
                name="brh"
                style={{ margin: "5px 5px", width: "90%", padding: "6px" }}
              >
                {branches.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.brch_code}-{branch.brch_nm}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="col-md-6 text-center mt-4"
              style={{ marginLeft: "14%" }}
            >
              <button
                className="btn"
                style={{
                  background: "#004AAD",
                  color: "white",
                  padding: "6px 20px",
                }}
                onClick={handleSave}
              >
                Save{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="col-md-12 mt-4 m-5 mx-5">
            <table className="table table-bordered bg-light text-center">
              <thead>
                <tr>
                  <td style={{ background: "#1c6fb7", color: "white" }}>
                    SI.No
                  </td>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      background: "#1c6fb7",
                      color: "white",
                    }}
                  >
                    Staff Name
                  </td>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      background: "#1c6fb7",
                      color: "white",
                    }}
                  >
                    User ID
                  </td>
                  <td style={{ background: "#1c6fb7", color: "white" }}>
                    Contact
                  </td>
                  <td style={{ background: "#1c6fb7", color: "white" }}>
                    Status
                  </td>
                  <td style={{ background: "#1c6fb7", color: "white" }}>
                    View More
                  </td>
                </tr>
              </thead>
              <tbody
                style={{ background: "transparent", verticalAlign: "middle" }}
              >
                {currentItems.map((staff, index) => (
                  <tr key={index}>
                    <td style={{ background: "#fff0" }}>
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </td>
                    <td style={{ background: "#fff0" }}>{staff.name}</td>
                    <td style={{ background: "#fff0" }}>{staff.us_nm}</td>
                    <td style={{ background: "#fff0" }}>{staff.contno}</td>
                    <td style={{ background: "#fff0", padding: "0rem 0.7rem" }}>
                      {staff.active_dactive.toUpperCase()}
                      <br />
                      <select
                        style={{ margin: "5px", padding: "2px" }}
                        onChange={(e) =>
                          handleStatusChange(staff.id, e.target.value)
                        }
                      >
                        <option value={staff.active_dactive}>
                          -- Status --
                        </option>
                        <option value="active">Active</option>
                        <option value="deactive">Deactive</option>
                      </select>
                    </td>
                    <td style={{ background: "#fff0" }}>
                      <FaEye
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleViewDetails(staff.us_nm)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="text-center mt-2">
              {currentPage > 1 && (
                <button
                  onClick={(e) => handleClick(e, currentPage - 1)}
                  className="mx-1 btn"
                >
                  Previous
                </button>
              )}
              {visiblePageNumbers.map((number) => (
                <button
                  key={number}
                  onClick={(e) => handleClick(e, number)}
                  className={`mx-1 btn ${
                    number === currentPage ? "active" : ""
                  }`}
                >
                  {number}
                </button>
              ))}
              {currentPage < Math.ceil(staffs.length / itemsPerPage) && (
                <button
                  onClick={(e) => handleClick(e, currentPage + 1)}
                  className="mx-1 btn"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {selectedStaff && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex:"1000",
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "8px",
              width: "400px",
              position: "relative", // Adding relative position to the container
            }}
          >
            <div className="modal-header1">
              <b>Staff Details</b>
              <button
                onClick={() => setSelectedStaff(null)}
                style={{ position: "absolute", top: "10px", right: "10px" }} // Adjusting position to top right
                className="btn close-button"
              >
                X
              </button>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{selectedStaff.name}</td>
                </tr>
                <tr>
                  <td>User ID</td>
                  <td>{selectedStaff.us_nm}</td>
                </tr>
                <tr>
                  <td>Passcode</td>
                  <td>{selectedStaff.paswd}</td>
                </tr>
                <tr>
                  <td>Mobile</td>
                  <td>{selectedStaff.contno}</td>
                </tr>
                <tr>
                  <td>Place</td>
                  <td>{selectedStaff.plce}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>{selectedStaff.addr}</td>
                </tr>
                <tr>
                  <td>DOJ</td>
                  <td>{selectedStaff.doj}</td>
                </tr>
                <tr>
                  <td>Salary</td>
                  <td>{selectedStaff.salry}</td>
                </tr>
                <tr>
                  <td>Branch</td>
                  <td>{selectedStaff.branchDetails}</td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "middle" }}>Actions</td>
                  <td>
                    <select
                      style={{ margin: "5px", padding: "2px" }}
                      onChange={(e) =>
                        handleActionOptionClick(
                          selectedStaff.id,
                          e.target.value
                        )
                      }
                    >
                      <option value="0">-- Actions --</option>
                      <option value="edit">Edit</option>
                      <option value="delete">Delete</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}{isEditModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex:'1000'
          }}
        >
          <div
            style={{
              background: '#fff',
              padding: '10px', // Reduce padding
              borderRadius: '8px',
              maxWidth: '400px', // Set maximum width
              width: '100%',
              position: 'relative', // Adding relative position to the container
            }}
          >
            <form>
              <div className="mb-3 modal-header1">
                <label>
                  <b>Edit Staff Details</b>
                </label>
                <button
                  onClick={() => handlePopupClose()}
                  className="btn"
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px', // Adjusting position to top right
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '1.2rem',
                  }}
                >
                  X
                </button>
              </div>
              <div className="mb-3" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={editedStaff.name}
                  onChange={handleEditInputChange}
                />
                <label>Login Password</label>
                <input
                  type={showPassword? 'text' : "password" }
                  name="paswd"
                  value={editedStaff.paswd}
                  onChange={handleEditInputChange}
                />
                 <div
            onClick={togglePasswordVisibility}
            style={{
              position: 'absolute',
              right: '25px',
              top: '22%',
              transform: 'translateY(-50%)',
              cursor: 'pointer'
            }}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </div>
                <label>Mobile</label>
                <input
                  type="number"
                  name="contno"
                  value={editedStaff.contno}
                  onChange={handleEditInputChange}
                />
                <label>Place</label>
                <input
                  type="text"
                  name="plce"
                  value={editedStaff.plce}
                  onChange={handleEditInputChange}
                />
                <label>Address</label>
                <textarea
                  name="addr"
                  value={editedStaff.addr}
                  onChange={handleEditInputChange}
                />
                <label>Date of Joining</label>
                <input
                min={doj}
                  type="date"
                  name="doj"
                  value={editedStaff.doj}
                  onChange={handleEditInputChange}
                />
                <label>Salary</label>
                <input
                  type="number"
                  name="salry"
                  value={editedStaff.salry}
                  onChange={handleEditInputChange}
                />
                <label>Branch Name</label>
                <select
                  name="brch_id"
                  value={editedStaff.brch_id}
                  onChange={handleEditInputChange}
                >
                  {branches.map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.brch_code}-{branch.brch_nm}
                    </option>
                  ))}
                </select>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn"
                  style={{
                    background: '#004AAD',
                    color: 'white',
                    padding: '6px 20px',
                  }}
                  onClick={handleEditSubmit}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}      
      <div className="fixed-bottom">
        <Footer />
      </div>
    </div>
  );
}

export default Staff;

import React, { useEffect, useState } from "react";
import Header from "./header";
import Header1 from "./header1";
import Footer from "./footer";
import { FaWarehouse } from "react-icons/fa";
import swal from "sweetalert2";
import API from "../api/API"; // Import the new api.js
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function Changepass() {
  useEffect(() => {
    // Check if username and password are present in session storage
    const username = sessionStorage.getItem("username");
    const password = sessionStorage.getItem("password");
    const userType = sessionStorage.getItem("userType");

    if (!username || !password) {
      // Redirect to login.js if username or password is missing
      window.location.href = "/";
    }

    if (userType === "staff") {
      window.history.back();
    }
  }, []);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  
  const handlePasswordChange = async () => {
    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      swal
        .fire({
          title: "Warning!",
          text: "New password and confirm password do not match",
          icon: "warning",
          confirmButtonText: "OK",
        })
        .then(() => {
          // Reload the page
          window.location.reload();
        });
      return;
    }

    // Make API call to update password
    try {
      const response = await fetch(API.updatePassword, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: sessionStorage.getItem("username"),
          oldPassword,
          newPassword,
          confirmPassword,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        swal
          .fire({
            title: "Success!",
            text: data.message,
            icon: "success",
            confirmButtonText: "OK",
          })
          .then(() => {
            // Reload the page
            window.location.reload();
          });
        // Optionally, you can redirect the user to another page
      } else {
        swal
          .fire({
            title: "Warning!",
            text: data.error,
            icon: "warning",
            confirmButtonText: "OK",
          })
          .then(() => {
            // Reload the page
            window.location.reload();
          });
      }
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  return (
    <div className="bghome" style={{ overflowY: "hidden",position:'absolute' }}>
      <Header />
      <Header1 />
      <div style={{ zoom: 0.8 }} className="col-md-12 title">
        <FaWarehouse className="mb-2" size={22} /> Change Password
      </div>
      <div className="vh-100">
        <div
          style={{ marginTop: "50px", width: "500px" }}
          className="chngfrm text-center col-md-6 lfb"
        >
          <div className="col-md-12" style={{position:'relative',}}>
            <label style={{ marginBottom: "5px", marginLeft: "10px" }}>
              <b style={{ fontWeight: "600" }}>Old Password</b>
            </label>
            <br />
            <input
             type={showPassword ? 'text' : 'password'}
              style={{
                margin: "5px 0px",
                marginLeft: "10px",
                width: "60%",
                padding: "6px",
              }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              autoFocus
            />
             <div
            onClick={togglePasswordVisibility}
            style={{
              position: 'absolute',
              right: '98px',
              top: '65%',
              transform: 'translateY(-50%)',
              cursor: 'pointer'
            }}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </div>
          </div>
          <div className="col-md-12" >
            <div className="mb-3" style={{position:'relative'}}>
              <label style={{ marginBottom: "5px", marginLeft: "10px" }}>
                <b style={{ fontWeight: "600" }}>New Password</b>
              </label>
              <br />
              <input
                type={showPassword1 ? 'text' : 'password'}
                style={{
                  margin: "5px 0px",
                  marginLeft: "10px",
                  width: "60%",
                  padding: "6px",
                }}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
               <div
            onClick={togglePasswordVisibility1}
            style={{
              position: 'absolute',
              right: '98px',
              top: '65%',
              transform: 'translateY(-50%)',
              cursor: 'pointer'
            }}
          >
            {showPassword1 ? <FaEyeSlash /> : <FaEye />}
          </div>
            </div>
            <div className="mt-4" style={{position:'relative'}}>
              <label style={{ marginBottom: "5px", marginLeft: "10px" }}>
                <b style={{ fontWeight: "600" }}>Confirm Password</b>
              </label>
              <br />
              <input
                type={showPassword2 ? 'text' : 'password'}
                style={{
                  margin: "5px 0px",
                  marginLeft: "10px",
                  width: "60%",
                  padding: "6px",
                }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
               <div
            onClick={togglePasswordVisibility2}
            style={{
              position: 'absolute',
              right: '98px',
              top: '65%',
              transform: 'translateY(-50%)',
              cursor: 'pointer'
            }}
          >
            {showPassword2 ? <FaEyeSlash /> : <FaEye />}
          </div>
            </div>
          </div>
          <div className="col-md-12 text-center mt-4">
            <button
              className="btn"
              style={{ background: "#004AAD", color: "white" }}
              onClick={handlePasswordChange}
            >
              Set Now
            </button>
          </div>
        </div>
      </div>
      <div className="fixed-bottom">
        <Footer />
      </div>    </div>
  );
}

export default Changepass;
